/*import { foreign } from './global';*/

const foreign = document.querySelector('body').classList.contains('foreign');

function closePreloader() {
  const preloaderContainer = document.querySelector('.preloader-container');
  preloaderContainer.classList.remove('active');
}

document.addEventListener('readystatechange', (event) => {
  if (event.target.readyState === 'complete') {
    closePreloader();
    home();
  }
});

/*export default*/ function home() {
  TweenMax.from('.home-title', 2, {
    autoAlpha: 0,
    y: 20,
    ease: Power3.easeInOut,
    delay: 1,
  });

  TweenMax.from('.home-text', 2, {
    autoAlpha: 0,
    y: 20,
    ease: Power3.easeInOut,
    delay: 1.2,
  });

  let ready = 0;

  //lottie animation
  const lottieAnim1 = lottie.loadAnimation({
    container: document.getElementById('circle-anim-1'), // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: foreign
      ? '../lottie/home/home-blur.json'
      : 'lottie/home/home-blur.json', // the path to the animation json
  });

  //lottie animation
  const lottieAnim2 = lottie.loadAnimation({
    container: document.getElementById('circle-anim-2'), // the dom element that will contain the animation
    renderer: 'svg',
    loop: false,
    autoplay: false,
    path: foreign ? '../lottie/home/home.json' : 'lottie/home/home.json', // the path to the animation json
  });

  const anims = [lottieAnim1, lottieAnim2];

  anims.forEach((anim) => {
    anim.addEventListener('data_ready', function () {
      ready++;
      if (ready === anims.length) {
        anims.forEach((anim) => anim.play());
      }
    });
  });

  let menuAlreadyOpened = false;

  lottieAnim1.addEventListener('complete', function () {
    timeout = setTimeout(function () {
      if (!menuAlreadyOpened) {
        document.querySelector('.hamburger').click();
      }
    }, 1000);
  });

  document.querySelector('.hamburger').addEventListener('click', function () {
    menuAlreadyOpened = true;
  });
}
/*
import documentReady from './libs/documentReady';
documentReady(home);
*/
